import Define from '../constants/define'
import baseAxios from './clientAPI'
import { StockArrayInterface } from '../models/Stock'
import { getUrlFilterParams } from '../utils'
import { TotalAccessoriesApi } from '../models'

const stockApi = {
  getAllStock(payload: {}): Promise<StockArrayInterface> {
    const url = '/warehouse/reference/getall'
    const { condition, pageSize, pageIndex } = getUrlFilterParams()
    const params = {
      limit: pageSize,
      offset: (pageIndex - 1) * pageSize || 0,
      client_code_nom: localStorage.getItem(Define.CHOOSING_CLIENT_CODENOM),
      company_code_nom: localStorage.getItem(Define.CHOOSING_COMPANY_CODENOM),
      warehouse_code_nom: localStorage.getItem(
        Define.CHOOSING_WAREHOUSE_CODENOM
      ),
      ...condition,
    }
    return baseAxios.get(url, { params })
  },

  getTotalStock(payload: {}): Promise<TotalAccessoriesApi> {
    const url = '/warehouse/accessories/get-total'
    const params = {
      client_code_nom: localStorage.getItem(Define.CHOOSING_CLIENT_CODENOM),
      company_code_nom: localStorage.getItem(Define.CHOOSING_COMPANY_CODENOM),
      warehouse_code_nom: localStorage.getItem(
        Define.CHOOSING_WAREHOUSE_CODENOM
      ),
      type: 'stock',
    }
    return baseAxios.get(url, { params })
  },
}

export default stockApi

export function loadMoreStockDetail(id: string, limit: number, offset: number) {
  const url = '/warehouse/stock/load-more-by-id'
  const params = {
    limit: limit,
    offset: offset,
    id,
    client_code_nom: localStorage.getItem(Define.CHOOSING_CLIENT_CODENOM),
    company_code_nom: localStorage.getItem(Define.CHOOSING_COMPANY_CODENOM),
    warehouse_code_nom: localStorage.getItem(Define.CHOOSING_WAREHOUSE_CODENOM),
  }
  return baseAxios.get(url, { params })
}
