import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  Attendu,
  AttenduArrayInterface,
  TotalAccessoriesApi,
} from '../../models'
import { StatusAPI } from '../../enum'
import { Metadata } from '../../models/common/common'

export interface AttenduSearchByNamePayload {
  name: string
}

export interface GetAllPayload {
  limit: Number
  offset: Number
}

interface currentAttenduObject {
  status: StatusAPI
  attendu?: Attendu
  error: string
}

interface currentAttenduList {
  status: StatusAPI
  attenduList: Attendu[]
  loading: boolean
  metadata: Metadata
}

interface totalAttenduList {
  status: StatusAPI
  number: number
  loading: boolean
}

interface DetailSection {
  validAtLeastOneLine?: boolean
  validAtLeastOneLineInEditMode?: boolean
}

export interface attenduState {
  detail: DetailSection
  currentAttendu: currentAttenduObject
  currentListAttendu: currentAttenduList
  totalAttendu: totalAttenduList
  error: string
}

const initialState: attenduState = {
  detail: {
    validAtLeastOneLine: false,
    validAtLeastOneLineInEditMode: false,
  },
  currentAttendu: {
    status: StatusAPI.initial,
    attendu: undefined,
    error: '',
  },
  currentListAttendu: {
    status: StatusAPI.initial,
    attenduList: [],
    loading: false,
    metadata: {
      count: 0,
      limit: 0,
      total: 0,
    },
  },
  totalAttendu: {
    status: StatusAPI.initial,
    number: 0,
    loading: false,
  },
  error: '',
}

const attenduSlice = createSlice({
  name: 'attendu',
  initialState,
  reducers: {
    initialAttendu(state) {
      state.currentAttendu = initialState.currentAttendu
    },
    //Create Attendu
    createAttendu(state, action: PayloadAction<Attendu>) {
      state.currentAttendu.status = StatusAPI.calling
    },
    createAttenduSuccess(state, action: PayloadAction<Attendu>) {
      state.currentAttendu.status = StatusAPI.success
      state.currentAttendu.attendu = action.payload
    },
    createAttenduFailed(state, action: PayloadAction<string>) {
      state.currentAttendu.status = StatusAPI.failure
      state.currentAttendu.error = action.payload
    },
    //getAll Attendu
    getAllAttendu(state, action: PayloadAction<{ isRealTime: boolean }>) {
      state.currentListAttendu.status = StatusAPI.calling
      state.currentListAttendu.loading = !action.payload.isRealTime
    },
    getAllAttenduSuccess(state, action: PayloadAction<AttenduArrayInterface>) {
      state.currentListAttendu.status = StatusAPI.success
      state.currentListAttendu.attenduList = action.payload.data.entry
      state.currentListAttendu.loading = false
      state.currentListAttendu.metadata = action.payload.data.metadata
    },
    getAllAttenduFailed(state, action: PayloadAction<any>) {
      state.currentListAttendu.status = StatusAPI.failure
      state.error = action.payload
      state.currentListAttendu.loading = false
    },
    //get All Attendu without filter condition
    getTotalAttendu(state, action: PayloadAction) {
      state.totalAttendu.status = StatusAPI.calling
      state.totalAttendu.loading = true
    },
    getTotalAttenduSuccess(state, action: PayloadAction<TotalAccessoriesApi>) {
      state.totalAttendu.status = StatusAPI.success
      state.totalAttendu.number = action.payload.data.total
      state.totalAttendu.loading = false
    },
    getTotalAttenduFailed(state, action: PayloadAction<any>) {
      state.totalAttendu.status = StatusAPI.failure
      state.error = action.payload
      state.totalAttendu.loading = false
    },
    getAttenduByName(state) {
      state.currentListAttendu.status = StatusAPI.calling
    },
    getAttenduByNameSuccess(state, action: PayloadAction<Attendu>) {
      state.currentAttendu.status = StatusAPI.success
      state.currentAttendu.attendu = action.payload
    },
    getAttenduByNameFailed(state, action: PayloadAction<any>) {
      state.currentAttendu.status = StatusAPI.failure
      state.error = action.payload
    },
    changeDetailSection(state, action: PayloadAction<DetailSection>) {
      state.detail.validAtLeastOneLine = action.payload?.validAtLeastOneLine
    },
  },
})

const attenduReducer = attenduSlice.reducer
export default attenduReducer

export const {
  initialAttendu,
  getAllAttendu,
  getAllAttenduSuccess,
  getAllAttenduFailed,
  getAttenduByName,
  getAttenduByNameSuccess,
  getAttenduByNameFailed,
  createAttendu,
  createAttenduSuccess,
  createAttenduFailed,
  changeDetailSection,
  getTotalAttenduSuccess,
  getTotalAttenduFailed,
  getTotalAttendu,
} = attenduSlice.actions
