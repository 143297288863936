import { PayloadAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { all, call, fork, put, take, takeLatest } from 'redux-saga/effects'
import destinataireApi from '../../http/destinataireApi'
import {
  Destinataire,
  DestinataireArrayInterface,
  DestinataireInterface,
  ModalGetByIdRequest,
} from '../../models'
import {
  createNewDestinataire,
  createNewDestinataireFailed,
  createNewDestinataireSuccess,
  DestinataireGetByCodePayload,
  DestinataireSearchByNameOrCodePayload,
  getAllDestinataire,
  getAllDestinataireFailed,
  getAllDestinataireSuccess,
  getDestinataireByCode,
  getDestinataireByCodeSuccess,
  getDestinataireByCodeFailed,
  searchDestinataireByNameOrCode,
  searchDestinataireByNameOrCodeFailed,
  searchDestinataireByNameOrCodeSuccess,
  getDestinataireByIdElementSuccess,
  getDestinataireByIdElementFailed,
  getDestinataireByIdElement,
} from '../reducers/destinataireSlice'

function* fetchAllDestinataire() {
  try {
    const item: DestinataireArrayInterface = yield call(
      destinataireApi.getAllDestinataire
    )
    yield put(getAllDestinataireSuccess(item.data.entry))
  } catch (error) {
    if (error instanceof AxiosError) {
      yield put(getAllDestinataireFailed(error.response?.data.error))
    } else {
      yield put(getAllDestinataireFailed('Something went wrong'))
    }
  }
}

function* fetchDestinataireByCode(payload: DestinataireGetByCodePayload) {
  try {
    const item: DestinataireInterface = yield call(
      destinataireApi.getDestinataireByName,
      payload
    )
    yield put(getDestinataireByCodeSuccess(item.data.entry))
  } catch (error) {
    if (error instanceof AxiosError) {
      yield put(getDestinataireByCodeFailed(error.response?.data.error))
    } else {
      yield put(getDestinataireByCodeFailed('Something went wrong'))
    }
  }
}

function* fetchDestinataireById(payload: ModalGetByIdRequest) {
  try {
    const item: DestinataireInterface = yield call(
      destinataireApi.getDestinataireByIdElement,
      payload
    )
    yield put(getDestinataireByIdElementSuccess(item.data.entry))
  } catch (error) {
    if (error instanceof AxiosError) {
      yield put(getDestinataireByIdElementFailed(error.response?.data.error))
    } else {
      yield put(getDestinataireByIdElementFailed('Something went wrong'))
    }
  }
}

function* createDestinataire(payload: Destinataire) {
  try {
    const item: DestinataireInterface = yield call(
      destinataireApi.createDestinataire,
      payload
    )
    yield put(createNewDestinataireSuccess(item.data.entry))
  } catch (error) {
    if (error instanceof AxiosError) {
      yield put(createNewDestinataireFailed(error.response?.data.error))
    } else {
      yield put(createNewDestinataireFailed('Something went wrong'))
    }
  }
}

function* searchDestinataire(payload: DestinataireSearchByNameOrCodePayload) {
  try {
    const item: DestinataireArrayInterface = yield call(
      destinataireApi.searchDestinataireByNameOrCode,
      payload
    )
    yield put(searchDestinataireByNameOrCodeSuccess(item.data.entry))
  } catch (error) {
    if (error instanceof AxiosError) {
      yield put(
        searchDestinataireByNameOrCodeFailed(error.response?.data.error)
      )
    } else {
      yield put(searchDestinataireByNameOrCodeFailed('Something went wrong'))
    }
  }
}

function* watchDestinataireFlow() {
  yield takeLatest(getAllDestinataire, fetchAllDestinataire)
  while (true) {
    const type: PayloadAction<DestinataireGetByCodePayload> = yield take(
      getDestinataireByCode.type
    )
    if (type) {
      yield fork(fetchDestinataireByCode, type.payload)
    }
  }
}

function* watchGetDestinataireByIdFlow() {
  while (true) {
    const type: PayloadAction<ModalGetByIdRequest> = yield take(
      getDestinataireByIdElement.type
    )
    if (type) {
      yield fork(fetchDestinataireById, type.payload)
    }
  }
}

function* watchCreateDestinataireFlow() {
  while (true) {
    const destinataire: PayloadAction<Destinataire> = yield take(
      createNewDestinataire.type
    )
    if (destinataire) {
      yield fork(createDestinataire, destinataire.payload)
    }
  }
}

function* watchSearchDestinataireFlow() {
  while (true) {
    const type: PayloadAction<DestinataireSearchByNameOrCodePayload> =
      yield take(searchDestinataireByNameOrCode.type)
    if (type) {
      yield fork(searchDestinataire, type.payload)
    }
  }
}

export default function* destinataireSaga() {
  yield all([
    fork(watchDestinataireFlow),
    fork(watchCreateDestinataireFlow),
    fork(watchSearchDestinataireFlow),
    fork(watchGetDestinataireByIdFlow),
  ])
}
