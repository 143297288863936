import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { StatusAPI } from '../../enum'
import {
  GetAllUserPayload,
  UtilisateurArrayInterface,
  UtilisateurResponse,
} from '../../models'

import { Metadata } from '../../models/common/common'

export interface GetAllPayload {
  limit: Number
  offset: Number
}

interface currentUtilisateurList {
  status: StatusAPI
  utilisateurList: UtilisateurResponse[]
  loading: boolean
  metadata: Metadata
  error: string
}

export interface utilisateurState {
  currentListUtilisateur: currentUtilisateurList
}

const initialState: utilisateurState = {
  currentListUtilisateur: {
    status: StatusAPI.initial,
    utilisateurList: [],
    loading: false,
    metadata: {
      limit: 0,
      count: 0,
      total: 0,
    },
    error: '',
  },
}

const utilisateurSlice = createSlice({
  name: 'utilisateur',
  initialState,
  reducers: {
    getAllUtilisateur(state, action: PayloadAction<GetAllUserPayload>) {
      state.currentListUtilisateur.loading = true
      state.currentListUtilisateur.status = StatusAPI.calling
    },
    getAllUtilisateurSuccess(
      state,
      action: PayloadAction<UtilisateurArrayInterface>
    ) {
      state.currentListUtilisateur.utilisateurList = action.payload.data.entry
      state.currentListUtilisateur.loading = false
      state.currentListUtilisateur.status = StatusAPI.success
      state.currentListUtilisateur.metadata = action.payload.data.metadata
      state.currentListUtilisateur.error =
        initialState.currentListUtilisateur.error
    },
    getAllUtilisateurFailed(state, action: PayloadAction<any>) {
      state.currentListUtilisateur.error = action.payload
      state.currentListUtilisateur.loading = false
      state.currentListUtilisateur.status = StatusAPI.failure
      state.currentListUtilisateur.utilisateurList =
        initialState.currentListUtilisateur.utilisateurList
      state.currentListUtilisateur.metadata =
        initialState.currentListUtilisateur.metadata
    },
  },
})

const utilisateurReducer = utilisateurSlice.reducer
export default utilisateurReducer

export const {
  getAllUtilisateur,
  getAllUtilisateurFailed,
  getAllUtilisateurSuccess,
} = utilisateurSlice.actions
