import { all, call, fork, put, takeLatest } from '@redux-saga/core/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { take } from 'redux-saga/effects'
import fournisseurApi from '../../http/fournisserApi'
import {
  Fournisseur,
  FournisseurArrayInterface,
  FournisseurInterface,
} from '../../models'
import {
  createNewFournisseur,
  createNewFournisseurFailed,
  createNewFournisseurSuccess,
  FournisseurGetByCodePayload,
  FournisseurSearchByNameOrCodePayload,
  getAllFournisseur,
  getAllFournisseurFailed,
  getAllFournisseurSuccess,
  getFournisseurByCode,
  getFournisseurByCodeFailed,
  getFournisseurByCodeSuccess,
  searchFournisseurByNameOrCode,
  searchFournisseurByNameOrCodeFailed,
  searchFournisseurByNameOrCodeSuccess,
} from '../reducers/fournisseurSlice'

function* fetchAllFournisseurs() {
  try {
    const item: FournisseurArrayInterface = yield call(
      fournisseurApi.getAllFournisseur
    )
    yield put(getAllFournisseurSuccess(item.data.entry))
  } catch (error) {
    if (error instanceof AxiosError) {
      yield put(getAllFournisseurFailed(error.message))
    } else {
      yield put(getAllFournisseurFailed('Something went wrong'))
    }
  }
}

function* fetchFournisseurByName(payload: FournisseurGetByCodePayload) {
  try {
    const item: FournisseurInterface = yield call(
      fournisseurApi.getFournisseurByName,
      payload
    )
    yield put(getFournisseurByCodeSuccess(item.data.entry))
  } catch (error) {
    if (error instanceof AxiosError) {
      yield put(getFournisseurByCodeFailed(error.message))
    } else {
      yield put(getFournisseurByCodeFailed('Something went wrong'))
    }
  }
}

function* createFournisseur(payload: Fournisseur) {
  try {
    const item: FournisseurInterface = yield call(
      fournisseurApi.createFournisseur,
      payload
    )
    yield put(createNewFournisseurSuccess(item.data.entry))
  } catch (error) {
    if (error instanceof AxiosError) {
      yield put(createNewFournisseurFailed(error.response?.data.error))
    } else {
      yield put(createNewFournisseurFailed('Something went wrong'))
    }
  }
}

function* searchFournisseur(payload: FournisseurSearchByNameOrCodePayload) {
  try {
    const item: FournisseurArrayInterface = yield call(
      fournisseurApi.searchFournisseurByNameOrCode,
      payload
    )
    yield put(searchFournisseurByNameOrCodeSuccess(item.data.entry))
  } catch (error) {
    if (error instanceof AxiosError) {
      yield put(searchFournisseurByNameOrCodeFailed(error.response?.data.error))
    } else {
      yield put(searchFournisseurByNameOrCodeFailed('Something went wrong'))
    }
  }
}

function* watchGetAllFournisseurFlow() {
  yield takeLatest(getAllFournisseur, fetchAllFournisseurs)
  while (true) {
    const type: PayloadAction<FournisseurGetByCodePayload> = yield take(
      getFournisseurByCode.type
    )
    if (type) {
      yield fork(fetchFournisseurByName, type.payload)
    }
  }
}

function* watchCreateFournisseurFlow() {
  while (true) {
    const fournisseur: PayloadAction<Fournisseur> = yield take(
      createNewFournisseur.type
    )
    if (fournisseur) {
      yield fork(createFournisseur, fournisseur.payload)
    }
  }
}

function* watchSearchFournisseurFlow() {
  while (true) {
    const type: PayloadAction<FournisseurSearchByNameOrCodePayload> =
      yield take(searchFournisseurByNameOrCode.type)
    if (type) {
      yield fork(searchFournisseur, type.payload)
    }
  }
}

export default function* fournisseurSaga() {
  yield all([
    fork(watchGetAllFournisseurFlow),
    fork(watchCreateFournisseurFlow),
    fork(watchSearchFournisseurFlow),
  ])
}
