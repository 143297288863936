import { PayloadAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { all, call, fork, put, take } from 'redux-saga/effects'
import referenceApi from '../../http/referenceApi'
import { ReferenceArrayInterface } from '../../models'
import { GetAllPayload } from '../reducers/commandeSlice'
import {
  getAllReference,
  getAllReferenceFailed,
  getAllReferenceSuccess,
} from '../reducers/referenceSlice'

function* fetchAllReference(payload: GetAllPayload) {
  try {
    const item: ReferenceArrayInterface = yield call(
      referenceApi.getAllReference,
      payload
    )
    yield put(getAllReferenceSuccess(item))
  } catch (error) {
    if (error instanceof AxiosError) {
      yield put(getAllReferenceFailed(error.response?.data.error))
    } else {
      yield put(getAllReferenceFailed('Something went wrong'))
    }
  }
}

function* watchReferenceFlow() {
  while (true) {
    const type: PayloadAction<GetAllPayload> = yield take(getAllReference.type)
    if (type) {
      yield fork(fetchAllReference, type.payload)
    }
  }
}

export default function* referenceSaga() {
  yield all([fork(watchReferenceFlow)])
}
