import { trim } from 'lodash'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { saveSelectors } from '../redux/reducers/selectorSlice'
import selectors from '../assets/selector.json'

/**
 *
 * @param id: Id of dom element to focus
 * @param delay: miliseconds time delay to async dom update
 *
 * Focus to element by Id with delay time to prevent synchronous action
 */
export function focusById(id: string, delay?: number) {
  setTimeout(() => {
    document.getElementById(id)?.focus()
  }, delay || 0)
}

function focusListIds(ids?: string[]) {
  ids?.every((id: string) => {
    // In case there are list of elements, will choose the first one that is not disable
    const nextElement = document.getElementById(trim(id))
    const isDisabled =
      nextElement?.children?.[0]?.hasAttribute('disabled') ||
      nextElement?.hasAttribute('disabled')
    const isVisible =
      // @ts-ignore
      nextElement?.offsetWidth > 0 && nextElement?.offsetHeight > 0
    // @ts-ignore
    if (nextElement && !isDisabled && isVisible) {
      nextElement.focus()
      return false
    }
    return true
  })
}

function focusOnNextInput(e: KeyboardEvent) {
  const focusedElement = document.activeElement
  const nextId =
    // pass data-next-id or aria-roledescription property to define next id
    // @ts-ignore
    focusedElement?.dataset?.nextId || focusedElement?.ariaRoleDescription
  if (nextId) {
    e.preventDefault()
    if (nextId.includes(',')) {
      // In case there are list of elements, will choose the first one that is not disable
      const ids = nextId.split(',')
      focusListIds(ids)
    } else {
      document.getElementById(nextId)?.focus()
    }
  }
}

function focusOnPreviousInput(e: KeyboardEvent) {
  const focusedElement = document.activeElement
  const previousId =
    // pass data-previous-id or aria-keyshortcuts property to define previous id
    // @ts-ignore
    focusedElement?.dataset?.previousId || focusedElement?.ariaKeyShortcuts
  if (previousId) {
    e.preventDefault()
    if (previousId.includes(',')) {
      const ids = previousId.split(',')
      focusListIds(ids)
    } else {
      document.getElementById(previousId)?.focus()
    }
  }
}

export default function usePressTab() {
  const dispatch = useDispatch()

  // fetch list of ID config from json file and save to reducer to use globally
  useEffect(() => {
    dispatch(saveSelectors(selectors))
  }, [dispatch])

  function shouldPreventTab() {
    const bodyOfModal = document.getElementsByClassName('ant-modal-body')
    const footerOfModal = document.getElementsByClassName('ant-modal-footer')
    const modal = bodyOfModal?.[0]
    const footer = footerOfModal?.[0]
    if (
      [...(modal?.classList || [])].includes('disable-all-event') ||
      [...(footer?.classList || [])].includes('disable-all-event')
    ) {
      return true
    }
    const popupAppearing = document.getElementsByClassName('ant-popover')?.[0]
    if (
      popupAppearing &&
      popupAppearing.textContent?.includes(
        'Êtes-vous sûr de vouloir supprimer cet élément?'
      ) &&
      ![...(popupAppearing.classList || [])].includes('ant-popover-hidden')
    ) {
      return true
    }
    return false
  }

  // listen to keyboard change in special case
  useEffect(() => {
    document.addEventListener('keydown', function (event) {
      // handle when press tab
      if (event.key === 'Tab') {
        if (!shouldPreventTab()) {
          if (!event.shiftKey) {
            focusOnNextInput(event)
          } else {
            focusOnPreviousInput(event)
          }
        } else {
          event.preventDefault()
        }
      }
      // handle when press enter
      // @ts-ignore
      if (event.key === 'Enter' && !event.target?.value) {
        // @ts-ignore
        document.activeElement?.click && document.activeElement.click()
      }
    })
    return () => {
      document.removeEventListener('keydown', () => {})
    }
  }, [])

  // listen when an element is focusing then make element wrap of it has focusing style if need
  useEffect(() => {
    document.addEventListener('focusin', function (event) {
      // @ts-ignore
      const focusingId = event.target?.id
      if (
        document
          .getElementById(`wrapper-${focusingId}`)
          // @ts-ignore
          ?.contains(event.target)
      ) {
        document
          .getElementById(`wrapper-${focusingId}`)
          ?.classList.add('focused')
      }
    })

    document.addEventListener('focusout', function (event) {
      document
        .getElementsByClassName('focused')?.[0]
        ?.classList.remove('focused')
    })
  }, [])
}
