import { PhotoResponseType } from '../enum/photoType'
import { FileResponse } from '../models'
import { DocumentStatus } from '../enum/DocumentStatus'

//function convert photo from api response type
export const convertPhotoType = (fileData: FileResponse[]) => {
  const errFile = fileData.filter(
    (file) => file.source === PhotoResponseType.ERR
  )
  const infoFile = fileData.filter(
    (file) => file.source === PhotoResponseType.INFOR
  )
  const uploadFile = fileData.filter(
    (file) =>
      file.source !== PhotoResponseType.ERR &&
      file.source !== PhotoResponseType.INFOR
  )

  // only show one button envoyer at one moment, the first Ajour will show
  const showEnvoyerButtonItem = uploadFile.find(
    (item) => item?.statut === DocumentStatus.Ajour
  )

  if (showEnvoyerButtonItem) showEnvoyerButtonItem.showEnvoyer = true

  return {
    errFile,
    infoFile,
    uploadFile,
  }
}
