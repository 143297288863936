import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { StatusAPI, ActionType } from '../../enum'

import { AttCommon, Fournisseur } from '../../models'

export interface FournisseurGetByCodePayload {
  code: string
}

export interface FournisseurSearchByNameOrCodePayload {
  data: string
  client_code_nom: string
}

interface currentFournisseurObject {
  status: StatusAPI
  fournisseur?: Fournisseur
  error: string
  action: ActionType
}

interface currentFournisseurList {
  status: StatusAPI
  fournisseurList: Fournisseur[]
  loading: boolean
  action: ActionType
}

interface fournisseurState {
  currentFournisseur: currentFournisseurObject
  currentFournisseurEdit: AttCommon
  currentFournisseurList: currentFournisseurList
}

const initialState: fournisseurState = {
  currentFournisseur: {
    status: StatusAPI.initial,
    fournisseur: undefined,
    error: '',
    action: ActionType.NONE,
  },
  currentFournisseurEdit: {},
  currentFournisseurList: {
    status: StatusAPI.initial,
    fournisseurList: [],
    loading: false,
    action: ActionType.NONE,
  },
}

const fournisseurSlice = createSlice({
  name: 'fournisseur',
  initialState,
  reducers: {
    clearCurrentFournisseur(state) {
      state.currentFournisseur = initialState.currentFournisseur
      state.currentFournisseurList = initialState.currentFournisseurList
    },
    getFournisseurByCode(
      state,
      action: PayloadAction<FournisseurGetByCodePayload>
    ) {
      state.currentFournisseur.status = StatusAPI.calling
      state.currentFournisseur.action = ActionType.GET_BY_CODE
    },
    getFournisseurByCodeSuccess(state, action: PayloadAction<Fournisseur>) {
      state.currentFournisseur.status = StatusAPI.success
      state.currentFournisseur.fournisseur = action.payload
      state.currentFournisseur.action = ActionType.GET_BY_CODE
    },
    getFournisseurByCodeFailed(state, action: PayloadAction<string>) {
      state.currentFournisseur.status = StatusAPI.failure
      state.currentFournisseur.error = action.payload
      state.currentFournisseur.action = ActionType.GET_BY_CODE
    },
    getAllFournisseur(state) {
      state.currentFournisseurList.loading = true
      state.currentFournisseurList.action = ActionType.GET_ALL
    },
    getAllFournisseurSuccess(state, action: PayloadAction<Fournisseur[]>) {
      state.currentFournisseurList.loading = false
      state.currentFournisseurList.fournisseurList = action.payload
      state.currentFournisseurList.action = ActionType.GET_ALL
    },
    getAllFournisseurFailed(state, action: PayloadAction<string>) {
      state.currentFournisseurList.loading = false
      state.currentFournisseurList.action = ActionType.GET_ALL
    },
    createNewFournisseur(state, action: PayloadAction<Fournisseur>) {
      state.currentFournisseur.status = StatusAPI.calling
      state.currentFournisseur.action = ActionType.CREATE
    },
    createNewFournisseurSuccess(state, action: PayloadAction<Fournisseur>) {
      state.currentFournisseur.fournisseur = action.payload
      state.currentFournisseur.status = StatusAPI.success
      state.currentFournisseur.action = ActionType.CREATE
    },
    createNewFournisseurFailed(state, action: PayloadAction<any>) {
      state.currentFournisseur.error = action.payload
      state.currentFournisseur.status = StatusAPI.failure
      state.currentFournisseur.action = ActionType.CREATE
    },
    searchFournisseurByNameOrCode(
      state,
      action: PayloadAction<FournisseurSearchByNameOrCodePayload>
    ) {
      state.currentFournisseurList.loading = true
      state.currentFournisseurList.action = ActionType.SEARCH_BY_CODE_NOM
    },
    searchFournisseurByNameOrCodeSuccess(
      state,
      action: PayloadAction<Fournisseur[]>
    ) {
      state.currentFournisseurList.loading = false
      state.currentFournisseurList.fournisseurList = action.payload
      state.currentFournisseurList.action = ActionType.SEARCH_BY_CODE_NOM
    },
    searchFournisseurByNameOrCodeFailed(state, action: PayloadAction<any>) {
      state.currentFournisseurList.loading = false
      state.currentFournisseurList.action = ActionType.SEARCH_BY_CODE_NOM
    },
    setCurrentFournisseurEdit(state, action: PayloadAction<AttCommon>) {
      state.currentFournisseurEdit = action.payload
    },
  },
})

const fournisseurReducer = fournisseurSlice.reducer
export default fournisseurReducer

export const {
  getFournisseurByCode,
  getFournisseurByCodeSuccess,
  getFournisseurByCodeFailed,
  getAllFournisseur,
  getAllFournisseurSuccess,
  getAllFournisseurFailed,
  createNewFournisseur,
  createNewFournisseurSuccess,
  createNewFournisseurFailed,
  searchFournisseurByNameOrCode,
  searchFournisseurByNameOrCodeSuccess,
  searchFournisseurByNameOrCodeFailed,
  clearCurrentFournisseur,
  setCurrentFournisseurEdit,
} = fournisseurSlice.actions
