import {
  FournisseurArrayInterface,
  FournisseurInterface,
} from '../models/Fournisseur'
import baseAxios from './clientAPI'

const fournisseurApi = {
  getAllFournisseur(): Promise<FournisseurInterface> {
    const url = '/warehouse/fournisseur/all'
    return baseAxios.get(url)
  },

  getFournisseurByName(payload?: {
    code: string
  }): Promise<FournisseurInterface> {
    const url = `/warehouse/fournisseur/get`
    const params = {
      data: payload?.code,
    }
    return baseAxios.post(url, params)
  },

  getFournisseurByID(payload?: { id: string }): Promise<FournisseurInterface> {
    const url = `/warehouse/fournisseur?id=${payload?.id}`
    return baseAxios.get(url)
  },

  createFournisseur(payload = {}): Promise<FournisseurInterface> {
    const url = '/warehouse/fournisseur'
    return baseAxios.post(url, payload)
  },

  searchFournisseurByNameOrCode(payload: {
    data: string
    client_code_nom: string
  }): Promise<FournisseurArrayInterface> {
    const url = '/warehouse/fournisseur/search_by_nom_code'

    return baseAxios.post(url, payload)
  },
}

export default fournisseurApi
