import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface pageState {
  currentPage: string
  rootPage: string
}

const initialState: pageState = {
  currentPage: '',
  rootPage: '',
}

const pageSlice = createSlice({
  name: 'page',
  initialState,
  reducers: {
    changePage(state, action: PayloadAction<string>) {
      state.currentPage = action.payload
    },
    changeRootPage(state, action: PayloadAction<string>) {
      state.rootPage = action.payload
    },
  },
})

const pageReducer = pageSlice.reducer

export default pageReducer

export const { changePage, changeRootPage } = pageSlice.actions
