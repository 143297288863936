/**
 *
 * @returns all results from random function string, int, ID, ...
 */

// program to generate random strings

// declare all characters
var uuid = require('uuid')
const characters =
  'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
const numberics = '0123456789'

export function randomString(length: number) {
  let result = ' '
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }

  return result
}

export function randomInt(length: number) {
  let result = ' '
  const charactersLength = numberics.length
  for (let i = 0; i < length; i++) {
    result += numberics.charAt(Math.floor(Math.random() * charactersLength))
  }

  return result
}

export function randomID(str: String) {
  return str + uuid.v4()
}

export function randomMounvementStatus() {
  var index = Math.floor(Math.random() * MouvementArray.length)
  return MouvementArray[index]
}

export function randomTypeStatus() {
  var index = Math.floor(Math.random() * TypeArray.length)
  return TypeArray[index]
}

export function randomEtatStatus() {
  var index = Math.floor(Math.random() * EtatType.length)
  return EtatType[index]
}

export function randomStatut() {
  var index = Math.floor(Math.random() * StatutType.length)
  return StatutType[index]
}

export function randomConditionnementType() {
  var index = Math.floor(Math.random() * ConditionnementType.length)
  return ConditionnementType[index]
}

export function randomEmplacementType() {
  var index = Math.floor(Math.random() * EmplacementType.length)
  return EmplacementType[index]
}

export function randomNameObj() {
  var index = Math.floor(Math.random() * NameObj.length)
  var pre = randomInt(8)
  return pre + '_' + NameObj[index]
}

export const randomKey = () => uuid.v4()
const MouvementArray: string[] = [
  'Annulation Picking',
  'Arrivage sans attendu',
  'Changement de conditionnement',
  'Copacking',
  'Complément de Palette',
  'DEsadv SAP/R3',
  'Entrée Normale',
  'Entrée de Régule',
  'Arrivage avec attendu',
  'Mouvement',
  'Passage en picking',
  'Panachage',
  'Purchase Order',
  'Purchase Order',
  'Repalettisation sortie Palette haute',
  'Retour client',
  'Sortie diverse',
  'Sortie normale',
  'Sortie de régularisation',
]

interface Item {
  pre?: string
  detail?: string
}

const TypeArray: Item[] = [
  {
    pre: '10',
    detail: 'Réception sans attendus',
  },
  {
    pre: '28',
    detail: 'Entrée inventaire positif',
  },
  {
    pre: '2M',
    detail: 'Entrée manquant sur arrivage',
  },
  {
    pre: '71',
    detail: 'Sortie de Régularisation',
  },
  {
    pre: '7G',
    detail: 'Picking',
  },
  {
    pre: '11',
    detail: "Réintégration d'un BL valide en sortie",
  },
  {
    pre: '29',
    detail: 'Entrée casse positive',
  },
  {
    pre: '2N',
    detail: 'Entrée Casse Tps Nestlé',
  },
  {
    pre: '72',
    detail: 'Sortie par demande rech. emp. manuelle',
  },
  {
    pre: '7H',
    detail: 'Passage en picking',
  },
  {
    pre: '12',
    detail: 'Arrivage avec attendus',
  },
  {
    pre: '2A',
    detail: 'Entrée par changement de CDN',
  },
  {
    pre: '2P',
    detail: 'Entrée Prélèvement Qualité',
  },
  {
    pre: '73',
    detail: 'Sortie par demande rech. emp. automatique',
  },
  {
    pre: '7I',
    detail: 'Panachage',
  },
  {
    pre: '13',
    detail: "Commande d'achat",
  },
  {
    pre: '2B',
    detail: 'Entrée par panachage de CDN',
  },
  {
    pre: '2T',
    detail: 'Entrée Casse Transport',
  },
  {
    pre: '74',
    detail: 'Sortie par mouvement rech. emp. manuelle',
  },
  {
    pre: '7K',
    detail: 'Purchase Order',
  },
  {
    pre: '14',
    detail: 'Copacking à effectuer',
  },
  {
    pre: '2C',
    detail: 'Entrée de régularisation',
  },
  {
    pre: '2T',
    detail: 'Entrée Casse Transport',
  },
  {
    pre: '75',
    detail: 'Sortie par mouvement rech. emp. automatique',
  },
  {
    pre: '7L',
    detail: 'Retour usine pour Contrôle qualité',
  },
  {
    pre: '20',
    detail: 'Entrée rch. emp. manuelle',
  },
  {
    pre: '2D',
    detail: 'Entrée remise en stock A.R',
  },
  {
    pre: '2V',
    detail: 'Diff Inventaire',
  },
  {
    pre: '76',
    detail: 'Sortie par picking rech. emp. manuelle',
  },
  {
    pre: '7Y',
    detail: 'Repalettisation sortie Palette haute',
  },
  {
    pre: '21',
    detail: 'Entrée rch. emp. automatique',
  },
  {
    pre: '2E',
    detail: 'Mutation automatique Stock',
  },
  {
    pre: '2W',
    detail: 'Périmé Clientele',
  },
  {
    pre: '77',
    detail: 'Sortie par picking rech. emp. automatique',
  },
  {
    pre: '80',
    detail: 'Picking',
  },
  {
    pre: '22',
    detail: 'Entrée par commande rch. emp. manuelle',
  },
  {
    pre: '2F',
    detail: 'Mise en stock directe',
  },
  {
    pre: '2Y',
    detail: 'Entrée pour changement lot soldeur',
  },
  {
    pre: '78',
    detail: 'Sortie par demande EDI rech. emp. manuelle',
  },
  {
    pre: '81',
    detail: 'Passage en picking',
  },
  {
    pre: '23',
    detail: 'Entrée par commande rch. emp. automatique',
  },
  {
    pre: '2G',
    detail: "Entrée par mouvement d'emplacement",
  },
  {
    pre: '2Z',
    detail: 'Entrée Copacking',
  },
  {
    pre: '79',
    detail: 'Sortie par demande EDI rech. emp. automatique',
  },
  {
    pre: 'SO',
    detail: 'Panachage',
  },
  {
    pre: '24',
    detail: 'Entrée par mouvement rch. emp. manuelle',
  },
  {
    pre: '2H',
    detail: 'Entrée par réapro picking automatique',
  },
  {
    pre: '30',
    detail: 'Stock',
  },
  {
    pre: '7A',
    detail: 'Sortie par changement de Cnd',
  },
  {
    pre: '25',
    detail: 'Entrée par mouvement rch. emp. automatique',
  },
  {
    pre: '2I',
    detail: 'Entrée par complément de palette ',
  },
  {
    pre: '60',
    detail: 'Demande de sortie manuelle',
  },
  {
    pre: '7B',
    detail: 'Sortie par panachage de Cnd',
  },
  {
    pre: '26',
    detail: 'Entrée par picking rch. emp. manuelle',
  },
  {
    pre: '2K',
    detail: 'Entrée Picking automatique',
  },
  {
    pre: '61',
    detail: 'Demande de sortie E.D.I',
  },
  {
    pre: '7C',
    detail: 'Sortie de régularisation',
  },
  {
    pre: '27',
    detail: 'Entrée par picking rch. emp. automatique',
  },
  {
    pre: '2L',
    detail: 'Annulation Picking Entrée Pal',
  },
  {
    pre: '70',
    detail: 'Sortie Rch.Emp. manuelle',
  },
  {
    pre: '7E',
    detail: 'Mutation automatique stock',
  },
]

const EtatType: string[] = ['processing', 'done', 'blocked']
const StatutType: string[] = [
  'satut_commercialisable',
  'statut_bloqué_usine',
  'statut_bloqué_entrepot',
  'statut_cq',
  'statut_bloqué',
]
const ConditionnementType: string[] = ['packed', 'unpacked']
const EmplacementType: string[] = [
  'standard',
  'preparation',
  'picking',
  'reception',
]

const NameObj: string[] = [
  'Grégoire Aubert-Monnier',
  'Isaac Lemonnier',
  'Théodore Evrard',
  'Matthieu Gomes',
  'Lucie Guibert',
  'Honoré Tessier',
  'Margaud Chrétien',
]

export function generateUUID() {
  // Public Domain/MIT
  var d = new Date().getTime() //Timestamp
  var d2 =
    (typeof performance !== 'undefined' &&
      performance.now &&
      performance.now() * 1000) ||
    0 //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = Math.random() * 16 //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0
      d = Math.floor(d / 16)
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0
      d2 = Math.floor(d2 / 16)
    }
    return (c === 'x' ? r : r && 0x3 | 0x8).toString(16)
  })
}
