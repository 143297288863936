export enum DocumentStatus {
  Envoye = 'Envoyé',
  Ajour = ' À jour',
  Obsolete = 'Obsolète',
  Annule = 'Annulé',
  Effectuee = 'Préparation effectuée',
  DASH = '-',
}

export enum DocumentType {
  Pallete,
  Carton,
}
