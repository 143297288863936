function generateLastDigit(input: number) {
  let array = input.toString().split('').reverse()

  let total = 0
  let i = 1
  array.forEach((number: any) => {
    number = parseInt(number)
    if (i % 2 === 0) {
      total = total + number
    } else {
      total = total + number * 3
    }
    i++
  })

  return Math.ceil(total / 10) * 10 - total
}

export const validateHasSameLastDigit = (input: number) => {
  return (
    generateLastDigit(
      +input.toString().substring(0, input.toString().length - 1)
    ).toString() ===
    input
      .toString()
      .charAt(input.toString().length - 1)
      .toString()
  )
}

export const validateHasSameLastDigit_8_13_14 = (input: string) => {
  if (input.length !== 8 && input.length !== 13 && input.length !== 14)
    return false
  return validateHasSameLastDigit(Number(input))
}

export const validateHasSameLastDigit_18 = (input: string) => {
  if (input.length !== 18) return false
  return validateHasSameLastDigit(Number(input))
}
