function randomString(lenString: number) {
  //define a variable consisting alphabets in small and capital letter
  var characters = 'abcdefghiklmnopqrstuvwxyz1234567890'

  //specify the length for the new string
  var result = ''

  //loop to select a new character in each iteration
  for (var i = 0; i < lenString; i++) {
    var num = Math.floor(Math.random() * characters.length)
    result += characters.substring(num, num + 1)
  }
  return result
}

function hashFnv32a(str: string) {
  /*jshint bitwise:false */
  var i, l
  var val = 0x811c9dc5

  for (i = 0, l = str.length; i < l; i++) {
    val ^= str.charCodeAt(i)
    val += (val << 1) + (val << 4) + (val << 7) + (val << 8) + (val << 24)
  }

  return (val >>> 0).toString(16).substring(-8)
}

export function genKey(numberDigit: number) {
  if (numberDigit <= 9) {
    return ['', false]
  }
  let timestamp = Date.now().toString()
  let hashTime = hashFnv32a(timestamp)
  if (hashTime.length > numberDigit) {
    console.log('Hashtime key is: ', hashTime)
    console.log('Expect key digit is: ', numberDigit)
  }
  let result = (
    hashTime + randomString(numberDigit - hashTime.length)
  ).toString()

  if (result.length !== numberDigit) {
    return ['', false]
  }
  return [result, true]
}
