import { PayloadAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { all, call, fork, put, take } from 'redux-saga/effects'
import utilisateurApi from '../../http/utilisateurApi'
import { GetAllUserPayload, UtilisateurArrayInterface } from '../../models'

import {
  getAllUtilisateur,
  getAllUtilisateurFailed,
  getAllUtilisateurSuccess,
} from '../reducers/utilisateurSlice'

function* fetchAllUtilisateur(payload: GetAllUserPayload) {
  try {
    const item: UtilisateurArrayInterface = yield call(
      utilisateurApi.getAllUser,
      payload
    )
    yield put(getAllUtilisateurSuccess(item))
  } catch (error) {
    if (error instanceof AxiosError) {
      yield put(getAllUtilisateurFailed(error.response?.data.error))
    } else {
      yield put(getAllUtilisateurFailed('Something went wrong'))
    }
  }
}

function* watchUtilisateurFlow() {
  while (true) {
    const type: PayloadAction<GetAllUserPayload> = yield take(
      getAllUtilisateur.type
    )
    if (type) {
      yield fork(fetchAllUtilisateur, type.payload)
    }
  }
}

export default function* utilisateurSaga() {
  yield all([fork(watchUtilisateurFlow)])
}
