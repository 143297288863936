import { PayloadAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { all, call, fork, put, take } from 'redux-saga/effects'
import clientWarehouseApi from '../../http/clientWarehouseApi'
import { ClientArrayInterface, ClientInterface } from '../../models'
import {
  getAllClient,
  getAllClientFailed,
  getAllClientSuccess,
  GetAllPayload,
} from '../reducers/clientSlice'
import { chooseClient, chooseClientSuccess } from '../reducers/accountSlice'

function* chooseClientSaga(payload: {
  client?: string
  company?: string
  warehouse?: string
}) {
  if (payload.client) {
    try {
      const item: ClientInterface = yield call(
        clientWarehouseApi.getClientByID,
        payload.client
      )
      yield put(
        chooseClientSuccess({
          ...payload,
          client: item,
        })
      )
    } catch (e) {}
  }
}

function* fetchAllClient(payload: GetAllPayload) {
  try {
    const item: ClientArrayInterface = yield call(
      clientWarehouseApi.getAllClient,
      payload
    )
    yield put(getAllClientSuccess(item))
  } catch (error) {
    if (error instanceof AxiosError) {
      yield put(getAllClientFailed(error.response?.data.error))
    } else {
      yield put(getAllClientFailed('Something went wrong'))
    }
  }
}

function* watchClientFlow() {
  while (true) {
    const type: PayloadAction<GetAllPayload> = yield take(getAllClient.type)
    if (type) {
      yield fork(fetchAllClient, type.payload)
    }
  }
}

function* watchChooseClientFlow() {
  while (true) {
    const type: PayloadAction<{
      client?: string
      company?: string
      warehouse?: string
    }> = yield take(chooseClient.type)
    if (type) {
      yield fork(chooseClientSaga, type.payload)
    }
  }
}

export default function* clientSaga() {
  yield all([fork(watchClientFlow), fork(watchChooseClientFlow)])
}
