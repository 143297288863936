import { Select } from 'antd'
import { CSSProperties, ReactNode, useState } from 'react'
import './CustomDropdown.scss'
import { t } from 'i18next'

export enum DropdownType {
  DEFAULT = 'default',
  PRIMARY = 'primary',
}

interface OptionProps {
  value?: string | number
  label?: ReactNode
}

interface CustomDropdownProps {
  title?: string
  width?: string
  placeholder?: string
  options?: OptionProps[]
  type?: string
  defaultValue?: string | number
  style?: CSSProperties
  onChange?:
    | ((value: any, option: OptionProps | OptionProps[]) => void)
    | undefined
  loading?: boolean
  value?: any
  className?: string
  dropdownWidth?: number
  passValueOnly?: boolean
  showAllOption?: boolean
}

/**
 * The @CustomDropdown customs UI about title, option, stype
 *
 * @version 0.1
 * @author [anhnq]
 * @author [anhht]
 */

const CustomDropdown = (props: CustomDropdownProps) => {
  const {
    width,
    title,
    placeholder,
    options,
    type,
    onChange,
    style,
    loading,
    value,
    className,
    passValueOnly,
    showAllOption,
  } = props

  const [isFocused, setIsFocused] = useState(false)

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  return (
    <div className={`custom-dropdown flex items-center justify-between`}>
      <span className="font-bold">{title} </span>
      <Select
        onFocus={handleFocus}
        onBlur={handleBlur}
        loading={loading}
        bordered={false}
        placeholder={
          <div className="text-sm font-medium text-[#808080]">
            {placeholder}
          </div>
        }
        value={value}
        className={` ${width} ${className} ${
          type === DropdownType.DEFAULT
            ? 'default rounded-md'
            : 'primary rounded-lg'
        }`}
        labelInValue
        options={
          showAllOption
            ? [{ label: t('all'), value: '' }, ...(options || [])]
            : options
        }
        style={{
          color: type === DropdownType.DEFAULT ? 'black' : 'white',
          backgroundColor: 'white',
          border: isFocused
            ? '1px solid #5794F7'
            : type === DropdownType.DEFAULT
            ? '1px solid #BEBEBE'
            : '2px solid #0189E3',
          ...style,
        }}
        onChange={(choice, option) => {
          // @ts-ignore
          onChange(passValueOnly ? choice.value : choice, option)
        }}
      />
    </div>
  )
}

export default CustomDropdown
