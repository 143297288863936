import {
  getAllStock,
  getAllStockFailed,
  getAllStockSuccess,
  GetAllPayload,
  getTotalStockSuccess,
  getTotalStockFailed,
  getTotalStock,
} from '../reducers/stockSlice'
import { all, call, fork, put } from '@redux-saga/core/effects'
import { AxiosError } from 'axios'
import { PayloadAction } from '@reduxjs/toolkit'
import { take } from 'redux-saga/effects'
import stockApi from '../../http/stockApi'
import { StockArrayInterface, TotalAccessoriesApi } from '../../models'

function* fetchAllStocks(payload: GetAllPayload) {
  try {
    const item: StockArrayInterface = yield call(stockApi.getAllStock, payload)
    yield put(getAllStockSuccess(item))
  } catch (error) {
    if (error instanceof AxiosError) {
      yield put(getAllStockFailed(error.response?.data.error))
    } else {
      yield put(getAllStockFailed('Something went wrong'))
    }
  }
}

function* fetchTotalStocks(payload: GetAllPayload) {
  try {
    const item: TotalAccessoriesApi = yield call(
      stockApi.getTotalStock,
      payload
    )
    yield put(getTotalStockSuccess(item))
  } catch (error) {
    if (error instanceof AxiosError) {
      yield put(getTotalStockFailed(error.response?.data.error))
    } else {
      yield put(getTotalStockFailed('Something went wrong'))
    }
  }
}

function* watchStockFlow() {
  while (true) {
    const type: PayloadAction<GetAllPayload> = yield take(getAllStock.type)
    if (type) {
      yield fork(fetchAllStocks, type.payload)
    }
  }
}

function* watchTotalFlow() {
  while (true) {
    const type: PayloadAction<GetAllPayload> = yield take(getTotalStock.type)
    if (type) {
      yield fork(fetchTotalStocks, type.payload)
    }
  }
}

export default function* stockSaga() {
  yield all([fork(watchStockFlow), fork(watchTotalFlow)])
}
