import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState: { data: any } = {
  data: {},
}

const selectorSlice = createSlice({
  name: 'selector',
  initialState,
  reducers: {
    saveSelectors(state, action: PayloadAction<object>) {
      state.data = action.payload
    },
  },
})

const selectorReducer = selectorSlice.reducer

export default selectorReducer

export const { saveSelectors } = selectorSlice.actions
