import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  Commande,
  CommandeArrayInterface,
  TotalAccessoriesApi,
} from '../../models'
import { StatusAPI } from '../../enum'
import { Metadata } from '../../models/common/common'

export interface GetAllPayload {
  limit: Number
  offset: Number
}

interface currentCommandeObject {
  status: StatusAPI
  commande?: Commande
  error: string
  loading: boolean
}

interface currentCommandeList {
  status: StatusAPI
  commandeList: Commande[]
  loading: boolean
  metadata: Metadata
}

interface totalCommandeList {
  status: StatusAPI
  number: number
  loading: boolean
}

export interface commandeState {
  currentCommande: currentCommandeObject
  currentListCommande: currentCommandeList
  totalCommande: totalCommandeList
  error: string
}

const initialState: commandeState = {
  currentCommande: {
    status: StatusAPI.initial,
    commande: undefined,
    error: '',
    loading: false,
  },
  currentListCommande: {
    status: StatusAPI.initial,
    commandeList: [],
    loading: false,
    metadata: {
      count: 0,
      limit: 0,
      total: 0,
    },
  },
  totalCommande: {
    status: StatusAPI.initial,
    number: 0,
    loading: false,
  },
  error: '',
}

const commandeSlice = createSlice({
  name: 'commande',
  initialState,
  reducers: {
    //Create Commande
    initialCommande(state) {
      state.currentCommande = initialState.currentCommande
    },
    createCommande(state, action: PayloadAction<Commande>) {
      state.currentCommande.status = StatusAPI.calling
      state.currentCommande.loading = true
    },
    createCommandeSuccess(state, action: PayloadAction<Commande>) {
      state.currentCommande.status = StatusAPI.success
      state.currentCommande.commande = action.payload
      state.currentCommande.loading = false
    },
    createCommandeFailed(state, action: PayloadAction<string>) {
      state.currentCommande.status = StatusAPI.failure
      state.currentCommande.error = action.payload
      state.currentCommande.loading = false
    },
    //getAll Commande
    getAllCommande(state, action: PayloadAction<{ isRealTime: boolean }>) {
      state.currentListCommande.status = StatusAPI.calling
      state.currentListCommande.loading = !action.payload.isRealTime
    },
    getAllCommandeSuccess(
      state,
      action: PayloadAction<CommandeArrayInterface>
    ) {
      state.currentListCommande.status = StatusAPI.success
      state.currentListCommande.commandeList = action.payload.data.entry
      state.currentListCommande.loading = false
      state.currentListCommande.metadata = action.payload.data.metadata
    },
    getAllCommandeFailed(state, action: PayloadAction<any>) {
      state.currentListCommande.status = StatusAPI.failure
      state.error = action.payload
      state.currentListCommande.loading = false
    },
    //get All Commande without filter condition
    getTotalCommande(state, action: PayloadAction) {
      state.totalCommande.status = StatusAPI.calling
      state.totalCommande.loading = true
    },
    getTotalCommandeSuccess(state, action: PayloadAction<TotalAccessoriesApi>) {
      state.totalCommande.status = StatusAPI.success
      state.totalCommande.number = action.payload.data.total
      state.totalCommande.loading = false
    },
    getTotalCommandeFailed(state, action: PayloadAction<any>) {
      state.totalCommande.status = StatusAPI.failure
      state.error = action.payload
      state.totalCommande.loading = false
    },
  },
})

const commandeReducer = commandeSlice.reducer
export default commandeReducer

export const {
  initialCommande,
  getAllCommande,
  getTotalCommande,
  getAllCommandeSuccess,
  getAllCommandeFailed,
  createCommande,
  createCommandeSuccess,
  createCommandeFailed,
  getTotalCommandeSuccess,
  getTotalCommandeFailed,
} = commandeSlice.actions
