import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { StatusAPI } from '../../enum'
import { Societe, SocieteArrayInterface } from '../../models'
import { Metadata } from '../../models/common/common'

export interface GetAllPayload {
  limit: Number
  offset: Number
}

interface currentSocieteList {
  status: StatusAPI
  societeList: Societe[]
  loading: boolean
  metadata: Metadata
  error: string
}

export interface SocieteState {
  currentListSociete: currentSocieteList
}

const initialState: SocieteState = {
  currentListSociete: {
    status: StatusAPI.initial,
    societeList: [],
    loading: false,
    metadata: {
      limit: 0,
      count: 0,
      total: 0,
    },
    error: '',
  },
}

const societeSlice = createSlice({
  name: 'Societe',
  initialState,
  reducers: {
    getAllSociete(state, action: PayloadAction<GetAllPayload>) {
      state.currentListSociete.loading = true
      state.currentListSociete.status = StatusAPI.calling
    },
    getAllSocieteSuccess(state, action: PayloadAction<SocieteArrayInterface>) {
      state.currentListSociete.societeList = action.payload.data.entry
      state.currentListSociete.loading = false
      state.currentListSociete.status = StatusAPI.success
      state.currentListSociete.metadata = action.payload.data.metadata
      state.currentListSociete.error = initialState.currentListSociete.error
    },
    getAllSocieteFailed(state, action: PayloadAction<any>) {
      state.currentListSociete.error = action.payload
      state.currentListSociete.loading = false
      state.currentListSociete.status = StatusAPI.failure
      state.currentListSociete.societeList =
        initialState.currentListSociete.societeList
      state.currentListSociete.metadata =
        initialState.currentListSociete.metadata
    },
  },
})

const societeReducer = societeSlice.reducer
export default societeReducer

export const { getAllSociete, getAllSocieteFailed, getAllSocieteSuccess } =
  societeSlice.actions
