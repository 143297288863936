import { all, call, fork, put } from '@redux-saga/core/effects'
import { PayloadAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { take } from 'redux-saga/effects'
import attenduApi from '../../http/attenduApi'
import {
  Attendu,
  AttenduArrayInterface,
  AttenduInterface,
  TotalAccessoriesApi,
} from '../../models'
import {
  createAttendu,
  createAttenduFailed,
  createAttenduSuccess,
  getAllAttendu,
  getAllAttenduFailed,
  getAllAttenduSuccess,
  GetAllPayload,
  getTotalAttendu,
  getTotalAttenduFailed,
  getTotalAttenduSuccess,
} from '../reducers/attenduSlice'

function* fetchCreateAttendu(payload: Attendu) {
  try {
    const item: AttenduInterface = yield call(attenduApi.createAttendu, payload)
    yield put(createAttenduSuccess(item.data.entry))
  } catch (error) {
    if (error instanceof AxiosError) {
      yield put(createAttenduFailed(error.response?.data.error))
    } else {
      yield put(createAttenduFailed('Something went wrong'))
    }
  }
}

function* fetchAllAttendus(payload: GetAllPayload) {
  try {
    const item: AttenduArrayInterface = yield call(
      attenduApi.getAllAttendu,
      payload
    )
    yield put(getAllAttenduSuccess(item))
  } catch (error) {
    if (error instanceof AxiosError) {
      yield put(getAllAttenduFailed(error.response?.data.error))
    } else {
      yield put(getAllAttenduFailed('Something went wrong'))
    }
  }
}

function* fetchTotalAttendus(payload: GetAllPayload) {
  try {
    const item: TotalAccessoriesApi = yield call(
      attenduApi.getTotalAttendu,
      payload
    )
    yield put(getTotalAttenduSuccess(item))
  } catch (error) {
    if (error instanceof AxiosError) {
      yield put(getTotalAttenduFailed(error.response?.data.error))
    } else {
      yield put(getTotalAttenduFailed('Something went wrong'))
    }
  }
}

function* watchAttenduFlow() {
  while (true) {
    const type: PayloadAction<GetAllPayload> = yield take(getAllAttendu.type)
    if (type) {
      yield fork(fetchAllAttendus, type.payload)
    }
  }
}

function* watchTotalAttenduFlow() {
  while (true) {
    const type: PayloadAction<GetAllPayload> = yield take(getTotalAttendu.type)
    if (type) {
      yield fork(fetchTotalAttendus, type.payload)
    }
  }
}

function* watchCreateAttenduFlow() {
  while (true) {
    const attendu: PayloadAction<Attendu> = yield take(createAttendu.type)
    if (attendu) {
      yield fork(fetchCreateAttendu, attendu.payload)
    }
  }
}

export default function* attenduSaga() {
  yield all([
    fork(watchAttenduFlow),
    fork(watchCreateAttenduFlow),
    fork(watchTotalAttenduFlow),
  ])
}
