import { Spin } from 'antd'
import './Loading.scss'
import { FC } from 'react'
import clsx from 'clsx'

/**
 * The @Loading is a component show loading icon when loading data.
 * @author [anhnq]
 * @version 0.1
 */
const Loading: FC<{ isSticky?: boolean }> = ({ isSticky }) => {
  return (
    <div
      className={clsx(
        'loading',
        isSticky &&
          'bg-white absolute w-full opacity-50 top-20 left-0 flex items-center justify-center z-10'
      )}
      style={{ height: isSticky ? 'calc(100vh - 210px)' : 'auto' }}
    >
      <Spin size="large" />
    </div>
  )
}

export default Loading
