import { PayloadAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { all, call, fork, put, take } from 'redux-saga/effects'
import clientWarehouseApi from '../../http/clientWarehouseApi'
import { FilterAccountType } from '../../models/Account'
import {
  ClientFilterArrayInterface,
  CompaniesFilterArrayInterface,
  getClients,
  getClientsFail,
  getClientsSuccess,
  getCompanies,
  getCompaniesFail,
  getCompaniesSuccess,
  getWarehouses,
  getWarehousesFail,
  getWarehousesSuccess,
  WarehousesFilterArrayInterface,
} from '../reducers/accountSlice'

function* fetchClient(payload: FilterAccountType) {
  try {
    const item: ClientFilterArrayInterface = yield call(
      clientWarehouseApi.getFilterClient,
      payload
    )
    yield put(getClientsSuccess(item.data.entry))
  } catch (error) {
    if (error instanceof AxiosError) {
      yield put(getClientsFail(error.response?.data.error))
    } else {
      yield put(getClientsFail('Something went wrong'))
    }
  }
}

function* fetchCompanies(payload: FilterAccountType) {
  try {
    const item: CompaniesFilterArrayInterface = yield call(
      clientWarehouseApi.getFilterCompany,
      payload
    )
    yield put(getCompaniesSuccess(item.data.entry))
  } catch (error) {
    if (error instanceof AxiosError) {
      yield put(getCompaniesFail(error.response?.data.error))
    } else {
      yield put(getCompaniesFail('Something went wrong'))
    }
  }
}

function* fetchWarehouses(payload: FilterAccountType) {
  try {
    const item: WarehousesFilterArrayInterface = yield call(
      clientWarehouseApi.getFilterWarehouse,
      payload
    )
    yield put(getWarehousesSuccess(item.data.entry))
  } catch (error) {
    if (error instanceof AxiosError) {
      yield put(getWarehousesFail(error.response?.data.error))
    } else {
      yield put(getWarehousesFail('Something went wrong'))
    }
  }
}

function* watchClientFlow() {
  while (true) {
    const data: PayloadAction<FilterAccountType> = yield take(getClients.type)
    if (data) {
      yield fork(fetchClient, data.payload)
    }
  }
}

function* watchCompanyFlow() {
  while (true) {
    const data: PayloadAction<FilterAccountType> = yield take(getCompanies.type)
    if (data) {
      yield fork(fetchCompanies, data.payload)
    }
  }
}

function* watchWarehouseFlow() {
  while (true) {
    const data: PayloadAction<FilterAccountType> = yield take(
      getWarehouses.type
    )
    if (data) {
      yield fork(fetchWarehouses, data.payload)
    }
  }
}

export default function* accountSaga() {
  yield all([
    fork(watchClientFlow),
    fork(watchCompanyFlow),
    fork(watchWarehouseFlow),
  ])
}
