import { TransporteurArrayInterface } from './../models/Transporteur'
import { ModalGetByIdRequest, TransporteurInterface } from '../models'
import baseAxios from './clientAPI'

const transporteurApi = {
  getAllTransporteurs(): Promise<TransporteurInterface> {
    const url = '/warehouse/transporteur/all'
    return baseAxios.get(url)
  },

  getTransporteurByCode(payload?: {
    code: string
  }): Promise<TransporteurInterface> {
    const url = `/warehouse/transporteur/get`
    const params = {
      data: payload?.code,
    }
    return baseAxios.post(url, params)
  },

  createTransporteur(payload = {}): Promise<TransporteurInterface> {
    const url = '/warehouse/transporteur'
    return baseAxios.post(url, payload)
  },

  searchTransporteurByNameOrCode(payload: {
    data: string
    client_code_nom: string
  }): Promise<TransporteurArrayInterface> {
    const url = '/warehouse/transporteur/search_by_nom_code'

    return baseAxios.post(url, payload)
  },

  getTransporteurByIdElement(
    payload: ModalGetByIdRequest
  ): Promise<TransporteurInterface> {
    const url = `/warehouse/transporteur/get-by-id-element?id=${payload.id}&id_contact=${payload.id_contact}&id_add=${payload.id_add}`
    return baseAxios.get(url)
  },
}

export default transporteurApi
