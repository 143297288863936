import React from 'react'
import { Provider } from 'react-redux'
// @ts-ignore
import App from './src/App'
import { store } from './src/app/store'

export default function Root(props) {
  return (
    // @ts-ignore
    <Provider store={store}>
      {/*// @ts-ignore*/}
      <App />
    </Provider>
  )
}
