import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import createSagaMiddleware from '@redux-saga/core'
import rootSaga from './rootSaga'
import modalReducer from '../features/stock/modal/customModalSlice'
import attenduReducer from '../redux/reducers/attenduSlice'
import fournisseurReducer from '../redux/reducers/fournisseurSlice'
import transporteurReducer from '../redux/reducers/transporteurSlice'
import destinataireReducer from '../redux/reducers/destinataireSlice'
import commandeReducer from '../redux/reducers/commandeSlice'
import pageReducer from '../redux/reducers/pageSlice'
import referenceReducer from '../redux/reducers/referenceSlice'
import clientReducer from '../redux/reducers/clientSlice'
import societeReducer from '../redux/reducers/societeSlice'
import utilisateurReducer from '../redux/reducers/utilisateurSlice'
import accountReducer from '../redux/reducers/accountSlice'
import stockReducer from '../redux/reducers/stockSlice'
import selectorReducer from '../redux/reducers/selectorSlice'

const sagaMiddleware = createSagaMiddleware()

export const store = configureStore({
  reducer: {
    modal: modalReducer,
    attendu: attenduReducer,
    fournisseur: fournisseurReducer,
    transporteur: transporteurReducer,
    destinataire: destinataireReducer,
    commande: commandeReducer,
    page: pageReducer,
    reference: referenceReducer,
    client: clientReducer,
    societe: societeReducer,
    utilisateur: utilisateurReducer,
    account: accountReducer,
    stock: stockReducer,
    selector: selectorReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
})

sagaMiddleware.run(rootSaga)

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
