import { GetAllUserPayload, UtilisateurArrayInterface } from '../models'
import baseAxios from './clientAPI'

const utilisateurApi = {
  getAllUser(payload: GetAllUserPayload): Promise<UtilisateurArrayInterface> {
    let clientId = payload.client_id ? `client_id=${payload.client_id}&` : ''
    let companyId = payload.company_id
      ? `company_id=${payload.company_id}&`
      : ''
    let warehouseId = payload.warehouse_id
      ? `warehouse_id=${payload.warehouse_id}&`
      : ''
    const url = `/user/all?${clientId}${companyId}${warehouseId}limit=${payload.limit}&offset=${payload.offset}`
    return baseAxios.get(url)
  },
}

export default utilisateurApi
