export enum StatusAttendu {
  CREATED = '10-0',
  UNASSIGNED = '10-2',
  ASSIGNED = '10-3',
  IN_PROGRESS = '10-4',
  SUSPENDU = '10-5',
  COMPLETE = '10-1',
  INSTOCK = 'in-stock',
  MAS_NON_ASSIGNE = '10-6',
  MAS_ASSIGNE = '10-7',
  MAS_EN_COURS = '10-8',
  MAS_SUSPENDU = '10-9',
  MAS_TERMINEE = '10-10',
}
