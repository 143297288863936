import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { StatusAPI } from '../../enum'
import { Client, ClientArrayInterface } from '../../models'
import { Metadata } from '../../models/common/common'

export interface GetAllPayload {
  limit: Number
  offset: Number
}

interface currentClientList {
  status: StatusAPI
  clientList: Client[]
  loading: boolean
  metadata: Metadata
  error: string
}

export interface clientState {
  currentListClient: currentClientList
}

const initialState: clientState = {
  currentListClient: {
    status: StatusAPI.initial,
    clientList: [],
    loading: false,
    metadata: {
      limit: 0,
      count: 0,
      total: 0,
    },
    error: '',
  },
}

const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    getAllClient(state, action: PayloadAction<GetAllPayload>) {
      state.currentListClient.loading = true
      state.currentListClient.status = StatusAPI.calling
    },
    getAllClientSuccess(state, action: PayloadAction<ClientArrayInterface>) {
      state.currentListClient.clientList = action.payload.data.entry
      state.currentListClient.loading = false
      state.currentListClient.status = StatusAPI.success
      state.currentListClient.metadata = action.payload.data.metadata
      state.currentListClient.error = initialState.currentListClient.error
    },
    getAllClientFailed(state, action: PayloadAction<any>) {
      state.currentListClient.error = action.payload
      state.currentListClient.loading = false
      state.currentListClient.status = StatusAPI.failure
      state.currentListClient.clientList =
        initialState.currentListClient.clientList
      state.currentListClient.metadata = initialState.currentListClient.metadata
    },
  },
})

const clientReducer = clientSlice.reducer
export default clientReducer

export const { getAllClient, getAllClientFailed, getAllClientSuccess } =
  clientSlice.actions
