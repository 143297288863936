//if text length is longer than number of digits, display text[0->(numberOfDigits-3)]...

export function renderDigitsByNumber(
  text: string | undefined,
  numberOfDigits: number,
  //check if render number of digits = digits want to display
  isEqual?: boolean
) {
  const NUMBER_OF_DOTS = 3
  if (!text) return '-'
  if (numberOfDigits < 3) return ''
  else if (text.length <= numberOfDigits) return text
  else if (isEqual) return `${text.substring(0, numberOfDigits)}...`
  else return `${text.substring(0, numberOfDigits - NUMBER_OF_DOTS)}...`
}

export function generateRandomPassword_onlyDigit(length: number) {
  return Math.random()
    .toString()
    .slice(-1 * length)
}
