const Define = {
  ACCESS_TOKEN: 'access_token',
  USERNAME: 'username',
  USER_ID: 'user_id',
  CLIENT_ID: 'client_id',
  ROLE: 'user_role',
  MAIL: 'mail',
  CHOOSING_CLIENT: 'choosing_client',
  CHOOSING_CLIENT_CODENOM: 'choosing_client_codenom',
  CHOOSING_COMPANY: 'choosing_company',
  CHOOSING_COMPANY_CODENOM: 'choosing_company_codenom',
  CHOOSING_WAREHOUSE: 'choosing_warehouse',
  CHOOSING_WAREHOUSE_CODENOM: 'choosing_warehouse_codenom',
  CODE: 'code',
  MAX_CDN_LEVEL: 3,
  DOMAIN: '.bfast-vn.net',
  ONE_MINUTE: 90000,
  CHOOSING_CLIENT_CODENOM_MULTIPLE: 'choosing_client_codenom_multiple',
  CHOOSING_COMPANY_CODENOM_MULTIPLE: 'choosing_company_codenom_multiple',
  CHOOSING_WAREHOUSE_CODENOM_MULTIPLE: 'choosing_warehouse_codenom_multiple',
  IS_REQUESTING: 'is_requesting',
}

export default Define
