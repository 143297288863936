import { AccountInterface, UserInterface } from '../models'
import baseAxios from './clientAPI'
import Cookies from 'js-cookie'
import axios from 'axios'

const authApi = {
  userSignin(credenticals = {}): Promise<AccountInterface> {
    const url = '/auth/signin_bo'
    return baseAxios.post(url, credenticals)
  },
  userLogout(credenticals = {}): Promise<AccountInterface> {
    const url = '/auth/signout'
    return baseAxios.post(url, credenticals)
  },

  getMe(): Promise<UserInterface> {
    const url = '/user/me'
    return baseAxios.get(url)
  },

  resetToken(): Promise<{
    data: {
      data: {
        access_token: string
        access_token_exp_at: number
        refresh_token: string
        refresh_token_exp_at: number
      }
    }
  }> {
    const refreshToken = Cookies.get('SSO_COOKIE-X')
    return axios.post(
      process.env.REACT_APP_API_ENDPOINT + '/auth/refresh_token',
      {
        token: refreshToken,
      }
    )
  },
}

export default authApi
